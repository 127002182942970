<template>
  <div class="">
    <no-data :isLoading="loading" class="animated fadeIn" v-show="loading"></no-data>
    <div v-show="!loading">
      <component v-bind:is="componentFile" class="animated fadeIn"></component>
      <slot>
      </slot>
    </div>

  </div>
</template>

<script>
  import {
    mapState,
  } from "vuex";

  export default {
    props: {
      routeLanguage: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        loading: true,
        componentFile: null,
      }
    },
    computed: {
      ...mapState(['language']),
    },
    watch: {
      language() {
        this.loading = true;
        setTimeout(() => {
          this.componentFile = () => import(this.routeLanguage + `${this.$i18n.locale}.vue`),
            setTimeout(() => {
              this.loading = false;
            }, 100)
        }, 200)
      }
    },
    methods: {},
    mounted() {
      this.componentFile = () => import(this.routeLanguage + `${this.$i18n.locale}.vue`);
      setTimeout(() => {
        this.loading = false;
      }, 500)
    }
  }
</script>

<style>
</style>