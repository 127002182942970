<template>
    <div>
        <div v-if="!$router.currentRoute.matched.some(record => record.path == '/dashboard')">
            <div class="mb-2 py-4 d-md-none bg-black" v-if="header">
                <div class="row justify-content-center">
                    <div class="col-auto">
                        <img src="@/assets/images/ecart-logo.png" class=" img-100 " alt="">
                    </div>
                </div>
            </div>
            <div class=" float-top-left p-4 d-md-none">
                <button class="btn text-white" @click=" $router.go(-1) ">
                    <i class=" fa fa-lg fa-level-down-alt fa-rotate-90 "> </i>
                </button>
            </div>
            <div class=" float-top-right p-4 d-md-none">
                <Languages :variant=" !dark ? 'none text-white' : 'primary' " />
            </div>
        </div>
        <slot name="content"></slot>
    </div>
</template>

<script>
    import {
        mapState
    } from 'vuex';
    import Languages from "./Languages.vue";
    export default {
        props: {
            dark: {
                type: Boolean,
                default: false,
            },
            header: {
                type: Boolean,
                default: false,
            },
        },
        components: {
            Languages,
        },
        computed: {
            ...mapState('session', ['user']),
        },
        methods: {},
        mounted() {}
    }
</script>