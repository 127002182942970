<template>
    <b-dropdown :variant="variant" :size="size" right>
      <template v-slot:button-content>
        <span class=" text-uppercase f-w-600" v-text=" $i18n.locale "> </span>
      </template>
      <b-dropdown-item v-for=" leng in CONST.LANGUAGES "  :key="leng.value" @click=" setLanguage( leng.value )  ">
        <span  v-text="leng.text"> </span>
      </b-dropdown-item>
    </b-dropdown>
</template>

<script>
import {
    mapMutations,
  } from "vuex";
  export default {
    props:{
      variant:{
        type:String,
        default:'',
      },
      size:{
        type:String,
        default:'',
      }
    },
    methods: {
      ...mapMutations(['setLanguage']),
    }
  }
</script>

<style>
</style>
